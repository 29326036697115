$build: 1743069070217;
$cdn: "/joxi";
$cdnProject: undefined;
.preloader{
  display:flex;
  background-color: rgba(34, 8, 2, 0.9);
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom:0;

  &_gamebox{
    position: static;
    grid-column: 2 / -2;
    width: 100%;
    text-align: center;
    padding:25px 0 20px;
    height: 60px;
    box-sizing: content-box;
    background-color: rgba(34, 8, 2, 0);
  }
  &_page{
    position:fixed;
    width: 100%;
    height: 100%;
    z-index: 300;
    padding-right:230px;
  }
  &_cashbox{
    z-index: 1090;
    bottom: -55px;
    background-color: rgba(34, 8, 2, 0.7);
  }
  &_popup{
    z-index: 9999;
    position: fixed;
  }
  &_over{
    top: 25px;
    left: 35px;
    right: 40px;
    bottom:40px;
  }
  &_table{
    background-color: rgba(34, 8, 2, 0);
    display:block;
    padding-top:125px;
  }
  &_button{
    z-index: 10;
    background: none;
    height: max-content;
    margin: auto;
  }

  &__missed-data {
    width: 100%;
    height: calc(100% + 12px);
    z-index: 1;
  }

  &.bonus-list {
    padding-top: 35px;
    background-color: rgba(34, 8, 2, 0);
  }

  &.bonus-item{
    z-index: 5;
  }

  &.sport-page {
    z-index: 1;
  }
}

.preloader-icon{
  width: 60px;
  height: 60px;
  margin: auto  ;
  background: green;
  animation: rotate360 1.3s infinite linear;
  background: url(  $cdn + '/wazamba/desktop/img/preloader.svg') 50% 50% no-repeat;
  background-size: contain;
}

@keyframes splashscreen {
  50%{ transform: rotate(180deg) scale(1.2); }
  100%{ transform: rotate(360deg) scale(1); }
}

.splash{
  position:fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5000 ;
  background-color: #0c1533;
  background-image: url( $cdn + "/wazamba/splash.svg" );
  background-position: 50% 50%;
  background-repeat: no-repeat;

  &.loaded{ display:none !important;}
}
