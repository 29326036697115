.preloader {
  background-color: #220802e6;
  display: flex;
  position: absolute;
  inset: 0;
}

.preloader_gamebox {
  text-align: center;
  box-sizing: content-box;
  background-color: #22080200;
  grid-column: 2 / -2;
  width: 100%;
  height: 60px;
  padding: 25px 0 20px;
  position: static;
}

.preloader_page {
  z-index: 300;
  width: 100%;
  height: 100%;
  padding-right: 230px;
  position: fixed;
}

.preloader_cashbox {
  z-index: 1090;
  background-color: #220802b3;
  bottom: -55px;
}

.preloader_popup {
  z-index: 9999;
  position: fixed;
}

.preloader_over {
  inset: 25px 40px 40px 35px;
}

.preloader_table {
  background-color: #22080200;
  padding-top: 125px;
  display: block;
}

.preloader_button {
  z-index: 10;
  background: none;
  height: max-content;
  margin: auto;
}

.preloader__missed-data {
  z-index: 1;
  width: 100%;
  height: calc(100% + 12px);
}

.preloader.bonus-list {
  background-color: #22080200;
  padding-top: 35px;
}

.preloader.bonus-item {
  z-index: 5;
}

.preloader.sport-page {
  z-index: 1;
}

.preloader-icon {
  background: url("/joxi/wazamba/desktop/img/preloader.svg") 50% / contain no-repeat;
  width: 60px;
  height: 60px;
  margin: auto;
  animation: 1.3s linear infinite rotate360;
}

@keyframes splashscreen {
  50% {
    transform: rotate(180deg)scale(1.2);
  }

  100% {
    transform: rotate(360deg)scale(1);
  }
}

.splash {
  z-index: 5000;
  background-color: #0c1533;
  background-image: url("/joxi/wazamba/splash.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  position: fixed;
  inset: 0;
}

.splash.loaded {
  display: none !important;
}
/*# sourceMappingURL=index.de96f636.css.map */
